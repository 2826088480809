import React from "react"
import { Redirect } from "react-router-dom"

//Management
import ChartHistory from "../pages/Management/ChartHistory"
import TradeHistory from "../pages/Management/TradeHistory"
import AllbaroProcess from "../pages/Management/AllbaroProcess"
import CollectHistory from "../pages/Management/CollectHistory"
import ExchangePlatform from "../pages/Management/ExchangePlatform"
import SellDetailScreen from "../pages/Management/SellDetailScreen"
import BuyDetailScreen from "../pages/Management/BuyDetailScreen"
import BuyRequestWrite from "../pages/Management/BuyRequestWrite"
import WearingHistory from "../pages/Management/WearingHistory"
import BusinessList from "../pages/Management/BusinessList"
import DisposeHistory from "pages/Management/DisposeHistory"
import DisposeScreen from "pages/Management/DisposeScreen"
import ProcessHistory from "pages/Management/ProcessHistory"
import EachSiteHistory from "pages/Management/EachSiteHistory"
import CompanyTotalList from "pages/Management/CompanyTotalList"

// Bill
import EmitterBill from "../pages/Bill/EmitterBill"
import CollectionBill from "../pages/Bill/CollectionBill"
import SettlementDetails from "../pages/Bill/SettlementDetails"
import ReportList from "../pages/Bill/ReportList"
// import payment_prepare from "../pages/Bill/PaymentPrepare"
import ReportTotalList from "../pages/Bill/ReportTotalList"

// TMS
import TMS from "../pages/TMS/TMS"

// My Weaver
import ProfileScreen from "../pages/MyWeaver/ProfileScreen"
import ContractManagementScreen from "../pages/MyWeaver/ContractManagementScreen"
// import Uncontracted from "../pages/MyWeaver/Uncontracted"
import ServiceCenterScreen from "../pages/MyWeaver/ServiceCenterScreen"
import BusinessListScreen from "../pages/MyWeaver/BusinessListScreen"
import BusinessManagementScreen from "../pages/MyWeaver/BusinessManagementScreen"
import AllbaroManagementScreen from "../pages/MyWeaver/AllbaroManagementScreen"
import ReportManagementScreen from "../pages/MyWeaver/ReportManagementScreen"
import PlatformProductScreen from "../pages/MyWeaver/PlatformProductScreen"
import MyProductScreen from "../pages/MyWeaver/MyProductScreen"
import SellRequestWrite from "../pages/MyWeaver/SellRequestWrite"
import BusinessListManagementScreen from "../pages/MyWeaver/BusinessListManagementScreen"
import DriverManagementScreen from "../pages/MyWeaver/DriverManagementScreen"
import WaverBarManagementScreen from "../pages/MyWeaver/WaverBarManagementScreen"
import WasteBinManagementScreen from "../pages/MyWeaver/WasteBinManagementScreen"
import CarNumberManagementScreen from "../pages/MyWeaver/CarNumberManagementScreen"
import BusinessCompanyTotalList from "../pages/MyWeaver/BusinessCompanyTotalList"
import WithdrawlUser from "pages/MyWeaver/WithdrawlUser"

// Write Screen
import ContractWrite from "../pages/MyWeaver/ContractWrite"

// Detail Screen
import ContractManagementDetailScreen from "../pages/MyWeaver/ContractManagementDetailScreen"

// Authentication related pages
// import Main from "../pages/Main/Main"
import KakaoMap from "../pages/KakaoMap/KakaoMap"
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Terms from "../pages/Authentication/Terms"
import RegisterStep1 from "../pages/Authentication/RegisterStep1/RegisterStep1"
import RegisterStep2 from "../pages/Authentication/RegisterStep2/RegisterStep2"
import RegisterStep3 from "../pages/Authentication/RegisterStep3/RegisterStep3"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import SelectType from "../pages/Authentication/SelectType"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import Detail from "../pages/Dashboard/Detail"
import DeviceAll from "../pages/Device/AllDevice"
import DevicePLT from "../pages/Device/PLTDevice"

import EachSiteTrade from "pages/Management/EachSiteTrade"

import UserMain from "../pages/UserMain/index"
import UserDetail from "../pages/UserMain/Detail"
import UserAdd from "../pages/UserMain/AddUser"

import CompanyMain from "../pages/CompanyMain/index"
import CompanyDetail from "../pages/CompanyMain/Detail"
import CompanyAdd from "../pages/CompanyMain/AddCompany"

import DeviceMain from "../pages/DeviceMain/index"
import DeviceAdd from "../pages/DeviceMain/AddDevice"

import HistoryMain from "../pages/HistoryMain/index"

import GearingMain from "../pages/GearingMain/index"

import ErrorMain from "../pages/ErrorMain/index"

import ControlId from "pages/ControlIdMain/ControlId"

const userRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/detail", component: Detail },
  { path: "/device-all", component: DeviceAll },
  { path: "/device-plt", component: DevicePLT },

  { path: "/usermain", component: UserMain },
  { path: "/userdetail", component: UserDetail },
  { path: "/useradd", component: UserAdd },

  { path: "/companymain", component: CompanyMain },
  { path: "/companydetail", component: CompanyDetail },
  { path: "/companyadd", component: CompanyAdd },

  { path: "/devicemain", component: DeviceMain },
  { path: "/deviceadd", component: DeviceAdd },

  { path: "/historymain", component: HistoryMain },

  { path: "/gearingmain", component: GearingMain },

  { path: "/errormain", component: ErrorMain },

  { path: "/controlidmain", component: ControlId },

  //calendar
  // { path: "/calendar", component: Calendar },

  //profile
  // { path: "/profile", component: UserProfile },

  //Management
  { path: "/chart-history", component: ChartHistory },
  { path: "/trade-history", component: TradeHistory },
  { path: "/allbaro-process", component: AllbaroProcess },
  { path: "/collect-history", component: CollectHistory },
  { path: "/exchange-platform", component: ExchangePlatform },
  { path: "/sell-detail-screen", component: SellDetailScreen },
  { path: "/buy-detail-screen", component: BuyDetailScreen },
  { path: "/buy-request-write", component: BuyRequestWrite },
  { path: "/wearing-history", component: WearingHistory },
  { path: "/business-list", component: BusinessList },
  { path: "/process-history", component: ProcessHistory },
  { path: "/dispose-history", component: DisposeHistory },
  { path: "/dispose-screen", component: DisposeScreen },
  { path: "/each-site-history", component: EachSiteHistory },
  { path: "/company-total-list", component: CompanyTotalList },
  
  //Bill
  { path: "/emitter-bill", component: EmitterBill },
  { path: "/collection-bill", component: CollectionBill },
  { path: "/settlement-details", component: SettlementDetails },
  // { path: "/payment_prepare", component: payment_prepare },
  { path: "/report-list", component: ReportList },
  { path: "/report-total-list", component: ReportTotalList },

  // TMS
  { path: "/TMS", component: TMS },
  
  //My Weaver
  { path: "/profile-screen", component: ProfileScreen }, 
  { path: "/contract-management-screen", component: ContractManagementScreen }, 
  // { path: "/Uncontracted", component: Uncontracted }, 
  { path: "/service-center-screen", component: ServiceCenterScreen }, 
  { path: "/business-list-screen", component: BusinessListScreen }, 
  { path: "/business-management-screen", component: BusinessManagementScreen }, 
  { path: "/allbaro-management-screen", component: AllbaroManagementScreen }, 
  { path: "/report-management-screen", component: ReportManagementScreen }, 
  { path: "/platform-product-screen", component: PlatformProductScreen }, 
  { path: "/my-product-screen", component: MyProductScreen }, 
  { path: "/sell-request-write", component: SellRequestWrite },
  { path: "/business-list-management-screen", component: BusinessListManagementScreen },
  { path: "/driver-management-screen", component: DriverManagementScreen },
  { path: "/waver-bar-management-screen", component: WaverBarManagementScreen },
  { path: "/waste-bin-management-screen", component: WasteBinManagementScreen },
  { path: "/car-number-management-screen", component: CarNumberManagementScreen },
  { path: "/business-company-total-list", component: BusinessCompanyTotalList },
  { path: "/withdrawl-user", component: WithdrawlUser },

  // Write Screen
  { path: "/contract-management-screen-write", component: ContractWrite }, 

  // Detail Screen
  { path: "/contract-management-screen-detail", component: ContractManagementDetailScreen },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/login" /> },
]

const authRoutes = [  
  { path: "/kakao-map", component: KakaoMap },
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/terms", component: Terms },
  { path: "/register-step1", component: RegisterStep1 },
  { path: "/register-step2", component: RegisterStep2 },
  // { path: "/register-step3", component: RegisterStep3 },
  { path: "/select-type", component: SelectType },
]

export { userRoutes, authRoutes }
