import PropTypes from 'prop-types'
import React,{useEffect, useState} from "react"
import { useLocation, useHistory } from "react-router-dom"
import MetaTags from 'react-meta-tags';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button
} from "reactstrap"
import "chartist/dist/scss/chartist.scss";

import loading from "../../assets/images/loading.gif";

import Board from './Component/Board';
import Util from "./Common/Util";
import Modal from "components/RopikCustom/Modal"

//i18n
import { withTranslation } from "react-i18next"
import { getIotDeviceList, getDropdownDevice, postAddDevice, postIotDevice, postUpdateDeviceId, postDeviceRangeSearch } from 'crud/auth.crud';

const DeviceMain = props => {
  const location = useLocation()
  const history = useHistory()

  const [onUpload, setOnUpload] = useState(false);
  const [pagesGroup, setPagesGroup] = useState(1)
  const [pageArr, setPageArr] = useState([pagesGroup, pagesGroup + 1, pagesGroup + 2, pagesGroup + 3, pagesGroup + 4])
  const [contentCount, setContentCount] = useState(0)
  const [dataList, setDataList] = useState([]);
  const [deviceList, setDeviceList] = useState([]);
  const [markerList, setMarkerList] = useState([]);
  const [inputData, setInputData] = useState({});
  const [page, setPage] = useState(1);
  const [idSearch1, setIdSearch1] = useState('')
  const [idSearch2, setIdSearch2] = useState('')
  const [inputId, setInputId] = useState('');
  const [inputMac, setInputMac] = useState('');
  const [inputNewId, setInputNewId] = useState('');
  const [inputNewMac, setInputNewMac] = useState('');
  const [inputLte, setInputLte] = useState('');
  const [inputMsn, setInputMsn] = useState('');
  const [inputMsisdn, setInputMsisdn] = useState('');
  const [inputExswv, setInputExswv] = useState('');
  const [inputAddress, setInputAddress] = useState('');
  const [inputHoldingType, setInputHoldingType] = useState('');
  const [inputSignageStandard, setInputSignageStandard] = useState('');
  const [inputSignContent, setInputSignContent] = useState('');
  const [inputSolarCapacity, setInputSolarCapacity] = useState('');
  const [inputBatteryCapacity, setInputBatteryCapacity] = useState('');
  const [inputConstructAt, setInputConstructAt] = useState('');
  const [inputNote, setInputNote] = useState('');
  const [inputProjectName, setInputProjectName] = useState('');
  const [inputSolarReferenceVoltage, setInputSolarReferenceVoltage] = useState('');
  const [inputCompany, setInputCompany] = useState('');
  const [inputCompanyDepart, setInputCompanyDepart] = useState('');
  const [search, setSearch] = useState('')
  const [exist, setExist] = useState(false)
  const [modalShow, setModalShow] = useState(false)

  const [checkSearch, setCheckSearch] = useState(true)

  const modalClose = () => {
    setModalShow(false);
  }

  const nextGetData = () => {
    setOnUpload(true)
    let newArr = []
    for (let i = pagesGroup - 1; i < pagesGroup + 4; i++) {
      if (i + 1 <= contentCount) {
        newArr.push(i + 1)
      }
    }
    setPageArr(newArr)
    setOnUpload(false)
  }

  const searchDevice = async() => {
    setCheckSearch(false)
    try {
      const params = {
        id: search || ''
      }
      const response = await postIotDevice(params)
      let content = response.data?.data
      if (content.length === 0){
        setExist(false)
        setInputId('')
        setInputMac('')
        setInputId('')
        setInputMac('')
        setInputCompany('')
        setInputCompanyDepart('')
        setInputProjectName('')
        setInputAddress('')
        setInputHoldingType('')
        setInputSignageStandard('')
        setInputSignContent('')
        setInputSolarCapacity('')
        setInputBatteryCapacity('')
        setInputSolarReferenceVoltage('')
        setInputNote('')
      } else {
        setExist(true)
        content = content[0]
        setInputId(content.id)
        setInputMac(content.mac)
        setInputCompany(content.company.name)
        setInputCompanyDepart(content.order_department)
        setInputProjectName(content.project_name)
        setInputAddress(content.address)
        setInputHoldingType(content.holding_type)
        setInputSignageStandard(content.signage_standard)
        setInputSignContent(content.sign_content)
        setInputSolarCapacity(content.solar_capacity)
        setInputBatteryCapacity(content.battery_capacity)
        setInputSolarReferenceVoltage(content.solar_reference_voltage)
        setInputNote(content.note)
      }
      console.log(response)
    } catch (error) {
      console.error(error)
    }
  }

  const searchDeviceRange = async() => {
    if(!idSearch1 || !idSearch2)
    return alert('id를 입력해 주세요.')
    try {
      let params = {
        startID: idSearch1,
        endID: idSearch2
      }
      const response = await postDeviceRangeSearch(params)
      if (response.status === 200){
        let content = response.data?.data
        if(content.length > 0){
          setDeviceList([...content])
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    nextGetData()
  }, [pagesGroup])

  //수정해야함
  const addDevice = async() => {
    var params = {
      "id": inputId,
      "mac": inputMac,
      "address": inputAddress,
      "holding_type": inputHoldingType,
      "signage_standard": inputSignageStandard,
      "sign_content": inputSignContent,
      "solar_capacity": inputSolarCapacity,
      "battery_capacity": inputBatteryCapacity,
      "note": inputNote,
      "company" : inputCompany,
      "comapny_depart" : inputCompanyDepart,
      "order_company" : inputCompany,
      "order_department" : inputCompanyDepart,
      "project_name" : inputProjectName,
      "solar_reference_voltage" : inputSolarReferenceVoltage
    }

    console.log(params)

    //900048
    let snRegex = /90[0-9]+/i;
    if (!snRegex.test(inputId)){
      alert("SN의 입력형식이 맞지 않습니다. 다시 입력해주세요 . 예)90XXXX")
      return
    }

    let regex = /([A-Za-z0-9]+(:[A-Za-z0-9]+)+)/i;
    if (!regex.test(inputMac)){
      alert("UUID의 입력형식이 맞지 않습니다. 다시 입력해주세요 . 예)AA:00:BB:11:CC:22")
      return
    }

    const response = await postAddDevice(params).then( (e) => {
      console.log("yeah ee...",e)
      if(exist==true){
        alert("수정이 완료되었습니다.")
      } else{
        alert("등록이 완료되었습니다.")
      }
      
      history.push('/dashboard')
    }).catch((error) => {
      console.log("error.response",error.response.status)

      if(error.response.status === 500){
        alert("서버 문제로 등록/수정에 실패 하였습니다. 관리자에게 문의하세요 ... [CODE: 500]")
      } else if (error.response.status === 400){
        alert("잘못 입력된 데이터가 있어 등록/수정에 실패 하였습니다. ... [CODE: 400]")
      }
    })
    
  }

  const updateDeviceId = async() => {
    try {
      const params = {
        id: inputId,
        changeId: inputNewId,
        mac: inputMac,
        changedMac: inputNewMac
      }

      let snRegex = /90[0-9]+/i;
    if (!snRegex.test(inputId) || !snRegex.test(inputNewId) ){
      alert("SN의 입력형식이 맞지 않습니다. 다시 입력해주세요 . 예)90XXXX")
      return
    }

    let regex = /([A-Za-z0-9]+(:[A-Za-z0-9]+)+)/i;
    if (!regex.test(inputMac) || !regex.test(inputNewMac)){
      alert("UUID의 입력형식이 맞지 않습니다. 다시 입력해주세요 . 예)AA:00:BB:11:CC:22")
      return
    }


      const response = await postUpdateDeviceId(params)
      console.log(response)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect( async() => {
    try {
      const response = await getDropdownDevice()
      if (response.status === 200) {
        const content = response.data?.data || []
        if (content.length > 0) {
          setDataList([...content])
        } else {
          setDataList([])
        }
      }
    } catch (error) {
      console.error(error)
    }
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>IOT MONITOR</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col xl={6}>
              <div className="position-relative app-search d-none d-lg-block" style={{ padding: "calc(18px / 2) 0" }}>
                <input
                  // type="text"
                  className="form-control"
                  placeholder="검색어를 입력해주세요..."
                  onChange={(e) => {
                    setSearch(e.target.value.length == 0 ? '' : e.target.value)
                  }}
                  onKeyDown={(e) => {
                    if (e.key == 'Enter') {
                      searchDevice()
                    }
                  }}
                />
                <span onClick={ () => {
                  searchDevice()
                }} className="fa fa-search" style={{ lineHeight: '56px', cursor: 'pointer' }} />
              </div>
              <Row>
                <Col md={12}>
                  <Card>
                    <CardBody style={{ padding: 0, borderRadius: 4, overflow: 'hidden' }}>
                      <div className="table-responsive">
                        <table className="table table-hover table-centered table-nowrap mb-0 chart-table">
                          <thead className="chart-thead">
                            <tr>
                              <th scope="col" className='bg-dark-ash'>단말기 신규 등록</th>
                            </tr>
                          </thead>
                        </table>
                        <div className="w-100" style={{ padding: 24 }}>

                          <div className='d-flex'>
                            <Col xl={5} className="mb-4 padding-right-8">
                              <p className="noMargin text-black font-weight-bold font-size-16">SN</p>
                              <input className="is-touched is-dirty av-valid form-control" value={inputId} onChange={(e) => {
                                  setInputId(e.target.value)
                                }}
                                placeholder={checkSearch ? '먼저 기기검색을 해주세요.': 'ex)900XXXX'} disabled={exist} />
                            </Col>
                            <Col xl={7} className="mb-4 padding-left-8">
                              <p className="noMargin text-black font-weight-bold font-size-16">UUID</p>
                              <div className='d-flex'>
                                <input className="is-touched is-dirty av-valid form-control" value={inputMac} onChange={(e) => {
                                    setInputMac(e.target.value)
                                  }}
                                  placeholder={checkSearch ? '먼저 기기검색을 해주세요.': 'ex)AA:00:BB:11:CC:22'} disabled={exist} />
                                <div className="text-end">
                                  <button
                                    className="btn btn-primary w-xs waves-effect waves-light bg-dark-ash margin-left-8" style={{height: 38}}
                                    onClick={() => { 
                                      if(exist){
                                        return alert('이미 등록된 기기입니다. \n내용을 수정하시려면 화면 아래 값을 변경하고. \n기기 정보 수정 버튼을 이용해 주십시오. ')
                                      }
                                      if(!exist && inputId.length === 0){
                                        return alert('ID는 필수로 입력하여야 합니다.')
                                      }
                                      addDevice() 
                                    }}
                                  >
                                    추가
                                  </button>
                                </div>
                              </div>
                            </Col>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardBody style={{ padding: 0, borderRadius: 4, overflow: 'hidden' }}>
                    <div className="table-responsive">
                        <table className="table table-hover table-centered table-nowrap mb-0 chart-table">
                          <thead className="chart-thead">
                            <tr>
                              <th scope="col" className='bg-dark-ash'>단말기 대체</th>
                            </tr>
                          </thead>
                        </table>
                        <div className="w-100" style={{ padding: 24 }}>
                        <div className='d-flex'>
                            <Col xl={5} className="mb-4 padding-right-8">
                              <p className="noMargin text-black font-weight-bold font-size-16">SN</p>
                              <input className="is-touched is-dirty av-valid form-control" value={inputId} onChange={(e) => {
                                  setInputId(e.target.value)
                                }}
                                placeholder={checkSearch ? '상단 검색기능을 이용해주세요.': 'ex)900XXXX'} disabled={exist} />
                            </Col>
                            <Col xl={7} className="mb-4 padding-left-8">
                              <p className="noMargin text-black font-weight-bold font-size-16">UUID</p>
                              <div className='d-flex'>
                                <input className="is-touched is-dirty av-valid form-control" value={inputMac} onChange={(e) => {
                                    setInputMac(e.target.value)
                                  }}
                                  placeholder={checkSearch ? '상단 검색기능을 이용해주세요.': 'ex)AA:00:BB:11:CC:22'} disabled={exist} />
                                
                              </div>
                            </Col>
                          </div>

                          <div className='d-flex'>
                          <Col xl={5} className="mb-4 padding-right-8">
                            <p className="noMargin text-black font-weight-bold font-size-16">대체할 콘트롤러 SN</p>
                            <input className="is-touched is-dirty av-valid form-control"
                              onChange={(e) => {
                                setInputNewId(e.target.value)
                              }}
                            placeholder='교체할 ID를 입력하여 주세요.' />
                          </Col>
                          <Col xl={7} className="mb-4 padding-left-8">
                            <p className="noMargin text-black font-weight-bold font-size-16">대체할 콘트롤러 UUID</p>
                            <div className='d-flex'>
                              <input className="is-touched is-dirty av-valid form-control"
                                onChange={(e) => {
                                  setInputNewMac(e.target.value)
                                }}
                              placeholder='교체할 UUID를 입력하여 주세요.' />
                              <div className="text-end">
                                <button
                                  className="btn btn-primary w-xs waves-effect waves-light bg-dark-ash margin-left-8" style={{height: 38}}
                                  onClick={() => {
                                    setModalShow(true)
                                  }}
                                >
                                  대체
                                </button>
                              </div>
                            </div>
                          </Col>
                        </div>
                        {!checkSearch && <>
                        
                        <div className="mb-4 mt-4">
                          <p className="noMargin text-black font-weight-bold font-size-16">발주처</p>
                          <div className='d-flex'>
                            <Col xl={8}>
                              <input value={inputCompany} className="is-touched is-dirty av-valid form-control mt-1" onChange={(e) => { setInputCompany(e.target.value) }} placeholder='직접 입력 또는, 우측에서 선택하여 주세요.' />
                            </Col>
                            <Col xl={4}>
                              <select id="inputCompany" name="inputCompany" className='dropdown-toggle waves-effect waves-light waver-select-box mt-1' style={{ marginLeft: 16, width: 'calc(100% - 16px)' }} value={""} onChange={(e) => {
                                setInputCompany(e.target.value)
                              }}>
                                <option value="" selected disabled hidden>선택 입력</option>
                                {dataList.length > 0 && Util.removeDup(dataList.map(item => item.company), 'name').map((item, index) => {
                                  return (
                                    <option key={item + `${index}`} value={item}>{item}</option>
                                  )
                                })}
                              </select>
                            </Col>
                          </div>
                        </div>
                        <div className="mb-4">
                          <p className="noMargin text-black font-weight-bold font-size-16">담당부서</p>
                          <div className='d-flex'>
                            <Col xl={8}>
                              <input value={inputCompanyDepart} className="is-touched is-dirty av-valid form-control mt-1" onChange={(e) => { setInputCompanyDepart(e.target.value) }} placeholder='직접 입력 또는, 우측에서 선택하여 주세요.' />
                            </Col>
                            <Col xl={4}>
                              <select id="inputCompanyDepart" name="inputCompanyDepart" className='dropdown-toggle waves-effect waves-light waver-select-box mt-1' style={{ marginLeft: 16, width: 'calc(100% - 16px)' }} value={""} onChange={(e) => {
                                setInputCompanyDepart(e.target.value)
                              }}>
                                <option value="" selected disabled hidden>선택 입력</option>
                                {dataList.length > 0 && Util.removeDup(dataList, 'order_department').map((item, index) => {
                                  if(item){
                                    return (
                                      <option key={item + `${index}`} value={item}>{item}</option>
                                    )
                                  }
                                })}
                              </select>
                            </Col>
                          </div>
                        </div>                  
                        <div className="mb-4">
                          <p className="noMargin text-black font-weight-bold font-size-16">공사명</p>
                          <div className='d-flex'>
                            <Col xl={8}>
                              <input value={inputProjectName} className="is-touched is-dirty av-valid form-control mt-1" onChange={(e) => { setInputProjectName(e.target.value) }} placeholder='직접 입력 또는, 우측에서 선택하여 주세요.' />
                            </Col>
                            <Col xl={4}>
                              <select id="inputProjectName" name="inputProjectName" className='dropdown-toggle waves-effect waves-light waver-select-box mt-1' style={{ marginLeft: 16, width: 'calc(100% - 16px)' }} value={""} onChange={(e) => {
                                setInputProjectName(e.target.value)
                              }}>
                                <option value="" selected disabled hidden>선택 입력</option>
                                {dataList.length > 0 && Util.removeDup(dataList, 'project_name').map((item, index) => {
                                  if(item){
                                    return (
                                      <option key={item + `${index}`} value={item}>{item}</option>
                                    )
                                  }
                                })}
                              </select>
                            </Col>
                          </div>
                        </div>
                        <div className="mb-4">
                          <p className="noMargin text-black font-weight-bold font-size-16">설치 위치</p>
                          <div className='d-flex'>
                            <Col xl={8}>
                              <input value={inputAddress} className="is-touched is-dirty av-valid form-control mt-1" onChange={(e) => { setInputAddress(e.target.value) }} placeholder='직접 입력 또는, 우측에서 선택하여 주세요.' />
                            </Col>
                            <Col xl={4}>
                              <select id="inputAddress" name="inputAddress" className='dropdown-toggle waves-effect waves-light waver-select-box mt-1' style={{ marginLeft: 16, width: 'calc(100% - 16px)' }} value={""} onChange={(e) => {
                                setInputAddress(e.target.value)
                              }}>
                                <option value="" selected disabled hidden>선택 입력</option>
                                {dataList.length > 0 && Util.removeDup(dataList, 'address').map((item, index) => {
                                  return (
                                    <option key={item + `${index}`} value={item}>{item}</option>
                                  )
                                })}
                              </select>
                            </Col>
                          </div>
                        </div>
                        <div className="mb-4">
                          <p className="noMargin text-black font-weight-bold font-size-16">지주 방식</p>
                          <div className='d-flex'>
                            <Col xl={8}>
                              <input value={inputHoldingType} className="is-touched is-dirty av-valid form-control mt-1" onChange={(e) => { setInputHoldingType(e.target.value) }} placeholder='직접 입력 또는, 우측에서 선택하여 주세요.' />
                            </Col>
                            <Col xl={4}>
                              <select id="inputHoldingType" name="inputHoldingType" className='dropdown-toggle waves-effect waves-light waver-select-box mt-1' style={{ marginLeft: 16, width: 'calc(100% - 16px)' }} value={""} onChange={(e) => {
                                setInputHoldingType(e.target.value)
                              }}>
                                <option value="" selected disabled hidden>선택 입력</option>
                                {dataList.length > 0 && Util.removeDup(dataList, 'holding_type').map((item, index) => {
                                  return (
                                    <option key={item + `${index}`} value={item}>{item}</option>
                                  )
                                })}
                              </select>
                            </Col>
                          </div>
                        </div>
                        <div className="mb-4">
                          <p className="noMargin text-black font-weight-bold font-size-16">표지판 규격</p>
                          <div className='d-flex'>
                            <Col xl={8}>
                              <input value={inputSignageStandard} className="is-touched is-dirty av-valid form-control mt-1" onChange={(e) => { setInputSignageStandard(e.target.value) }} placeholder='직접 입력 또는, 우측에서 선택하여 주세요.' />
                            </Col>
                            <Col xl={4}>
                              <select id="inputSignageStandard" name="inputSignageStandard" className='dropdown-toggle waves-effect waves-light waver-select-box mt-1' style={{ marginLeft: 16, width: 'calc(100% - 16px)' }} value={""} onChange={(e) => {
                                setInputSignageStandard(e.target.value)
                              }}>
                                <option value="" selected disabled hidden>선택 입력</option>
                                {dataList.length > 0 && Util.removeDup(dataList, 'signage_standard').map((item, index) => {
                                  return (
                                    <option key={item + `${index}`} value={item}>{item}</option>
                                  )
                                })}
                              </select>
                            </Col>
                          </div>
                        </div>
                        <div className="mb-4">
                          <p className="noMargin text-black font-weight-bold font-size-16">표지판 내용</p>
                          <div className='d-flex'>
                            <Col xl={8}>
                              <input value={inputSignContent} className="is-touched is-dirty av-valid form-control mt-1" onChange={(e) => { setInputSignContent(e.target.value) }} placeholder='직접 입력 또는, 우측에서 선택하여 주세요.' />
                            </Col>
                            <Col xl={4}>
                              <select id="inputSignContent" name="inputSignContent" className='dropdown-toggle waves-effect waves-light waver-select-box mt-1' style={{ marginLeft: 16, width: 'calc(100% - 16px)' }} value={""} onChange={(e) => {
                                setInputSignContent(e.target.value)
                              }}>
                                <option value="" selected={true} disabled hidden>선택 입력</option>
                                {dataList.length > 0 && Util.removeDup(dataList, 'sign_content').map((item, index) => {
                                  return (
                                    <option key={item + `${index}`} value={item}>{item}</option>
                                  )
                                })}
                              </select>
                            </Col>
                          </div>
                        </div>
                        <div className="mb-4">
                          <p className="noMargin text-black font-weight-bold font-size-16">솔라 규격</p>
                          <div className='d-flex'>
                            <Col xl={8}>
                              <input value={inputSolarCapacity} className="is-touched is-dirty av-valid form-control mt-1" onChange={(e) => { setInputSolarCapacity(e.target.value) }} placeholder='직접 입력 또는, 우측에서 선택하여 주세요.' />
                            </Col>
                            <Col xl={4}>
                              <select id="inputSolarCapacity" name="inputSolarCapacity" className='dropdown-toggle waves-effect waves-light waver-select-box mt-1' style={{ marginLeft: 16, width: 'calc(100% - 16px)' }} value={""} onChange={(e) => {
                                setInputSolarCapacity(e.target.value)
                              }}>
                                <option value="" selected={true} disabled hidden>선택 입력</option>
                                {dataList.length > 0 && Util.removeDup(dataList, 'solar_capacity').map((item, index) => {
                                  if(item){
                                    return (
                                      <option key={item + `${index}`} value={item}>{item}</option>
                                    )
                                  }
                                })}
                              </select>
                            </Col>
                          </div>
                        </div>
                        <div className="mb-4">
                          <p className="noMargin text-black font-weight-bold font-size-16">배터리 규격</p>
                          <div className='d-flex'>
                            <Col xl={8}>
                              <input value={inputBatteryCapacity} className="is-touched is-dirty av-valid form-control mt-1" onChange={(e) => { setInputBatteryCapacity(e.target.value) }} placeholder='직접 입력 또는, 우측에서 선택하여 주세요.' />
                            </Col>
                            <Col xl={4}>
                              <select id="inputBatteryCapacity" name="inputBatteryCapacity" className='dropdown-toggle waves-effect waves-light waver-select-box mt-1' style={{ marginLeft: 16, width: 'calc(100% - 16px)' }} value={""} onChange={(e) => {
                                setInputBatteryCapacity(e.target.value)
                              }}>
                                <option value="" selected={true} disabled hidden>선택 입력</option>
                                {dataList.length > 0 && Util.removeDup(dataList, 'battery_capacity').map((item, index) => {
                                  if(item){
                                    return (
                                      <option key={item + `${index}`} value={item}>{item}</option>
                                    )
                                  }
                                })}
                              </select>
                            </Col>
                          </div>
                        </div>
                        <div className="mb-4">
                          <p className="noMargin text-black font-weight-bold font-size-16">솔라 기준 전압</p>
                          <div className='d-flex'>
                            <Col xl={8}>
                              <input value={inputSolarReferenceVoltage} className="is-touched is-dirty av-valid form-control mt-1" onChange={(e) => { setInputSolarReferenceVoltage(e.target.value) }} placeholder='직접 입력 또는, 우측에서 선택하여 주세요.' />
                            </Col>
                            <Col xl={4}>
                              <select id="inputSolarReferenceVoltage" name="inputSolarReferenceVoltage" className='dropdown-toggle waves-effect waves-light waver-select-box mt-1' style={{ marginLeft: 16, width: 'calc(100% - 16px)' }} value={""} onChange={(e) => {
                                setInputSolarReferenceVoltage(e.target.value)
                              }}>
                                <option value="" selected={true} disabled hidden>선택 입력</option>
                                {dataList.length > 0 && Util.removeDup(dataList, 'solar_reference_voltage').map((item, index) => {
                                  if(item){
                                    return (
                                      <option key={item + `${index}`} value={item}>{item}</option>
                                    )
                                  }
                                })}
                              </select>
                            </Col>
                          </div>
                        </div>
                        <div className="mb-4">
                          <p className="noMargin text-black font-weight-bold font-size-16">비고</p>
                          <div className='d-flex'>
                            <Col xl={8}>
                              <input value={inputNote} className="is-touched is-dirty av-valid form-control mt-1" onChange={(e) => { setInputNote(e.target.value) }} placeholder='직접 입력 또는, 우측에서 선택하여 주세요.' />
                            </Col>
                            <Col xl={4}>
                              <select id="inputNote" name="inputNote" className='dropdown-toggle waves-effect waves-light waver-select-box mt-1' style={{ marginLeft: 16, width: 'calc(100% - 16px)' }} value={""} onChange={(e) => {
                                setInputNote(e.target.value)
                              }}>
                                <option value="" selected={true} disabled hidden>선택 입력</option>
                                {dataList.length > 0 && Util.removeDup(dataList, 'note').map((item, index) => {
                                  if(item){
                                    return (
                                      <option key={item + `${index}`} value={item}>{item}</option>
                                    )
                                  }
                                })}
                              </select>
                            </Col>
                          </div>
                        </div>

                        <button
                          className="btn btn-primary w-md waves-effect waves-light bg-dark-ash mb-4 mt-2"
                          onClick={() => { addDevice() }}
                        >
                          {exist ? "기기 정보 수정":"기기 신규 등록"}
                        </button>
                          </>
                          }
                        </div>
                      </div>
                      
                    
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <div className="position-relative app-search d-none d-lg-block" style={{ padding: "calc(18px / 2) 0" }}>
                <Row>
                  <Col xl={6}>
                    <input
                      className="form-control"
                      placeholder="최소범위 SN을 입력해 주세요..."
                      onChange={(e) => {
                        setIdSearch1(e.target.value.length == 0 ? '' : e.target.value)
                      }}
                      onKeyDown={(e) => {
                        if (e.key == 'Enter') {
                          searchDeviceRange()
                        }
                      }}
                    />
                  </Col>
                  <Col xl={6}>
                    <input
                      className="form-control"
                      placeholder="최대범위 SN을 입력해 주세요..."
                      onChange={(e) => {
                        setIdSearch2(e.target.value.length == 0 ? '' : e.target.value)
                      }}
                      onKeyDown={(e) => {
                        if (e.key == 'Enter') {
                          searchDeviceRange()
                        }
                      }}
                    />
                  </Col>
                </Row>
                <span onClick={ () => {
                  searchDeviceRange()
                }} className="fa fa-search" style={{ lineHeight: '56px', cursor: 'pointer' }} />
              </div>
              <Board
                title={'디바이스 리스트'}
                form={'post'}
                headers={[
                  {key : 'id', value: 'SN'},
                  {key : 'mac', value: 'UUID'},
                  {key : 'created_at', value: '생성시간'},
                ]} 
                dataList={deviceList} 
                boardKey={'device'}
                page={page}
                height={'1028px'}
                setPage={setPage}
                pagesGroup={pagesGroup}
                setPagesGroup={setPagesGroup}
                pageArr={pageArr}
                contentCount={contentCount} />
            </Col>
          </Row>
          
          {onUpload && 
            <div style={{
                position: "absolute",
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                background: 'rgba(255, 255, 255, 0.6)',
                zIndex: 999,
                display: "flex",
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <img src={loading} alt="로딩중입니다." width={'5%'}></img>
            </div>
          }

        </Container>
      </div>
      <Modal title={'교체 확인'} show={modalShow} onHide={modalClose} size="md">
        <div style={{ zIndex: 1, width: '100%' }}>
          <p style={{fontSize: '1rem', textAlign: 'center'}}>{'기기를 교체하시겠습니까?\n교체 시, 되돌릴 수 없습니다.'}</p>
          <div className='text-center'>
          <button className="btn btn-primary w-xs waves-effect waves-light bg-dark-ash margin-left-16" style={{height: 38}}
            onClick={() => {
              if(!inputId || !inputNewId){
                return alert('ID가 입력되지 않았습니다.')
              }else{
                if(!inputMac || !inputNewMac){
                  return alert('UUID가 입력되지 않았습니다.')
                } else {
                  updateDeviceId()
                }
                
              }
            }}
          >
            교체
          </button>
          <button className="btn btn-primary w-xs waves-effect waves-light bg-dark-ash margin-left-16" style={{height: 38}} onClick={modalClose}>
            취소
          </button>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  )
}

DeviceMain.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(DeviceMain)
