import axios from "axios";
//import store from "../../app/store/store";

// export const API_URL = "http://192.168.1.23/api/v1";
// export const API_URL = "http://127.0.0.1/api/v1";
// export const API_URL = "http://106.240.173.254/api/v1";
export const API_URL = "https://iot.ropiklabs.com/api/v1";
// export const API_URL = "http://52.79.253.46:12000/api/v1";

// export const API_URL = "/api/v1";

// export const axiosApi = axios.create({
//   baseURL: API_URL,
// })
// authToken 주입
// axiosApi.interceptors.request.use(
//   config => {
//       const {
//           auth: { authToken }
//       } = store.getState();

//       if (authToken) {
//           config.headers.Authorization = `Bearer ${authToken}`;
//       }
//       return config;
//   },
//   err => Promise.reject(err)
// );

export const LOGIN_URL = API_URL+"/auth/sign_in/";
export const GET_USER_DATA_URL = API_URL+"/member/search_member/"
export const REGISTER_URL = API_URL+"/auth/sign_up/";
export const CHECK_ID_URL = API_URL+"/auth/check_id/";
export const MAIN_RESPONSE_URL = API_URL+"/waste_collect/my_collect_chart/";
export const CHART_TOTAL_URL = API_URL+"/waste_collect/my_total_collect_chart/";
export const DASHBOARD_URL = API_URL+"/waste_collect/get_dashboard_data/";
export const NEWS_URL = API_URL+"/news/?page=1";
export const MY_PROFILE_URL = API_URL+"/member/my_profile/";

// 
export const GET_IOT_DEVICE = API_URL+"/iot_device/get_device/"
export const GET_IOT_DEVICE_URL = API_URL+"/iot_device/data_list/"
export const GET_IOT_DEVICE_HISTORY_URL = API_URL+"/iot_device_history/data_list/"
export const GET_IOT_DEVICE_ERROR_HISTORY_URL = API_URL+"/iot_device_history/get_error_history_list/"
export const GET_IOT_DEVICE_SOLAR_URL = API_URL+"/iot_device_history/data_list_solar/"
export const GET_IOT_DEVICE_BATTERY_URL = API_URL+"/iot_device_history/data_list_batt/"
export const GET_ALL_IOT_DEVICE_HISTORY_URL = API_URL+"/iot_device_history/"
export const POST_UPDATE_IOT_DEVICE = API_URL+"/iot_device/device_update/"
export const UPDATE_DEVICE_ID_URL = API_URL+"/iot_device/change_new_device/"
export const DEVICE_RANGE_SEARCH_URL = API_URL+"/iot_device/get_between_device/"

export const GET_USER_LIST_URL = API_URL+"/member"
export const POST_USER_SEARCH_LIST_URL = API_URL+"/member/search_member/"
export const GET_USER_DETAIL_URL = API_URL+'/member/'
export const POST_ADD_USER_URL = API_URL+"/member/"
export const POST_UPDATE_USER_URL = API_URL+"/member/update_member/"

export const POST_DEVICE_SEARCH_LIST_URL = API_URL+"/iot_device/search_device/"
export const POST_DEVICE_UNSIGN_SEARCH_LIST_URL = API_URL+"/iot_device/search_unsign_device/"
export const POST_ADD_DEVICE_URL = API_URL+"/iot_device/add_new_device/"
export const POST_CALL_SOCKET_TYPE_A_URL = API_URL+"/iot_device/call_socket_type_a/"
export const POST_DEVICE_PAIRING_URL = API_URL+"/iot_device/device_pairing/"
export const POST_DEVICE_UNPAIRING_URL = API_URL+"/iot_device/device_unpairing/"

export const POST_UNSIGN_DEVICE_SEARCH_LIST_URL = API_URL+"/unsign_iot_device/search_unsign_device/"
export const POST_UNSIGN_DEVICE_PAIRING_URL = API_URL+"/unsign_iot_device/device_pairing/"
export const POST_UNSIGN_DEVICE_UNPAIRING_URL = API_URL+"/unsign_iot_device/device_unpairing/"

export const POST_COMPANY_SEARCH_LIST_URL = API_URL+"/company/search_company/"
export const POST_ADD_COMPANY_URL = API_URL+"/company/"
export const GET_COMPANY_LIST_URL = API_URL+"/company/"
export const GET_COMPANY_URL = API_URL+"/company/"

export const GET_HISTORY_LIST_URL = API_URL+"/iot_device_history/get_history_list"
export const POST_HISTORY_SEARCH_LIST_URL = API_URL+"/iot_device_history/search_history/"

export const POST_PV_VOLT_UPDATE = API_URL+"/iot_device/pv_volt_update/"
export const POST_POSITION_UPDATE = API_URL+"/iot_device/position_update/"
export const POST_AS_STATUS_UPDATE = API_URL+"/iot_device/as_status_update/"


export const GET_DIMMING_LIST = API_URL+"/iot_device/search_dimming_device/"


// export const CONTRACT_URL = API_URL+"/contract/my_contract_list/";
export const CONTRACT_URL = API_URL+"/contract_company_group/my_contract_list/";
// export const CONTRACT_PATCH_URL = API_URL+"/contract_company_group/"
export const CONTRACT_PATCH_URL = API_URL+"/contract/insert_contract_file/"
export const COMPANY_URL = API_URL+"/company/";
export const CONTRACT_WRITE_URL = API_URL+"/contract/insert_contract/";
export const CONTRACT_WRITE_HISTORY_URL = API_URL+"/contract_history/";
export const TRADE_HISTORY_URL = API_URL+"/waste_collect/my_collect_list/";
export const MEMBER_RESPONSE_URL = API_URL+"/my_profile/"
export const TMS_COMPANY_RESPONSE_URL = API_URL+"/contract_company_group/my_company_contracted_company/";
export const TMS_RESPONSE_URL = API_URL+"/tms/my_tms/";
export const FAQ_URL = API_URL+"/faq/";
export const CONTRACT_DELETE_URL = API_URL+"/contract/";
export const CONTRACT_DETAIL_URL = API_URL+"/contract_company_group/contract_detail/";
export const OTHER_COMPANY_URL = API_URL+"/company/target_member_data/?id=";
export const PASSWORD_CHANGE_URL = API_URL+"/member/password_change/";
export const SIGN_CHANGE_URL = API_URL+"/member_file/uploads/";
export const ALARM_RESPONSE_URL = API_URL+"/alarm_history/data_list/?page=";
export const ALARM_VIEW_URL = API_URL+"/alarm_history/set_all_view/";
export const ALARM_DELETE_URL = API_URL+"/alarm_history/";
export const DRIVER_RESPONSE_URL = API_URL+"/waste_collect/my_driver_collect_list/";
export const DISPOSE_DATA_RESPONSE_URL = API_URL+"/dispose/my_driver_dispose_data/";
export const DRIVER_COMPANY_URL = API_URL+"/contract_company_group/my_company_contracted_company/";
export const LIST_DELETE_URL = API_URL+"/waste_collect/";
export const DISPOSE_WEARING_URL = API_URL+"/dispose/dispose_and_wearing/";
export const UNCOLLECT_UPLOAD_URL = API_URL+"/waste_collect/insert_un_collect/";
export const BUSINESS_LIST_URL = API_URL+"/company/children_company/";
export const NEW_BUSINESS_URL = API_URL+"/company/children_company_file_upload/";
export const SIGN_URL = API_URL+"/contract_company_group/contract_sign/";
export const PRODUCT_LIST_URL = API_URL+"/trade/trade_list/?page=1&type=sell";
export const PRODUCT_SELL_LIST_URL = API_URL+"/trade/trade_list/?page=";
export const PRODUCT_SELL_DETAIL_URL = API_URL+"/trade/";
export const PRODUCT_REQUEST_URL = API_URL+"/trade_request/insert_trade_request/";
export const SELL_COMPLETE_ONE_URL = API_URL+"/trade_request/";
export const SELL_COMPLETE_TWO_URL = API_URL+"/trade/";
export const SELL_COMPLETE_THREE_URL = API_URL+"/trade_history/";
export const SELL_COMPLETE_FOUR_URL = API_URL+"/trade_request_history/";
export const SELL_DELETE_ONE_URL = API_URL+"/trade_request/";
export const SELL_DELETE_TWO_URL = API_URL+"/trade/";
export const SELL_DELETE_THREE_URL = API_URL+"/trade_request_history/";
export const BUY_WRITE_URL = API_URL+"/trade/insert_trade/";
export const POST_WEARING_URL = API_URL+"/wearing/wearing_sum/";
export const POST_PROCESSING_WEARING_URL = API_URL+"/wearing/process_wearing/";
export const GET_WEARING_LIST_URL = API_URL+"/wearing/my_wearing_list/";
export const GET_COLLECT_LIST_URL = API_URL+"/waste_collect/collect_list/";
export const MY_PRODUCT_LIST_URL = API_URL+"/trade/my_trade_list/";
export const POST_COLLECT_DATA_URL = API_URL+"/waste_collect/children_company_collect/";
export const POST_MY_CONTRACT_URL = API_URL+"/contract_company_group/children_contract_list/";
export const POST_COLLECT_SUM_URL = API_URL+"/waste_collect/collect_sum/";
export const POST_DISPOSE_SUM_URL = API_URL+"/dispose/dispose_sum/";
export const SENDING_CODE_URL = API_URL+"/auth/send_code_email/";
export const CHECK_CODE_URL = API_URL+"/auth/check_verification_code/";
export const NEW_PASSWORD_URL = API_URL+"/auth/password_change/";
export const GET_DRIVER_LIST_URL = API_URL+"/company_member_group/data_list/";
export const CHECK_DRIVER_ID_URL = API_URL+"/auth/check_id/";
export const SAVE_DRIVER_ACCOUNT_URL = API_URL+"/auth/insert_driver/";
export const SAVE_DRIVER_EXCEL_URL = API_URL+"/auth/upload_driver_excel/";
export const DELETE_DRIVER_ACCOUNT_URL = API_URL+"/my_profile/";
export const UPDATE_DRIVER_LIST_URL = API_URL+"/my_profile/";
export const GET_WAVER_BAR_URL = API_URL+"/Iot_monitor_bar/data_list/";
export const CHECK_WAVER_BAR_NUMBER_URL = API_URL+"/Iot_monitor_bar/check_id/";
export const SAVE_WAVER_BAR_NUMBER_URL = API_URL+"/Iot_monitor_bar/";
export const SAVE_WAVER_BAR_EXCEL_URL = API_URL+"/Iot_monitor_bar/upload_Iot_monitor_bar_excel/";
export const DELETE_WAVER_BAR_NUMBER_URL = API_URL+"/Iot_monitor_bar/";
export const GET_BIN_LIST_URL = API_URL+"/waste_bin/data_list/";
export const GET_BIN_COMPANY_URL = API_URL+"/contract_company_group/my_company_contracted_company/";
export const CHECK_BIN_ID_URL = API_URL+"/waste_bin/check_id/";
export const SAVE_BIN_ACCOUNT_URL = API_URL+"/waste_bin/";
export const SAVE_BIN_EXCEL_URL = API_URL+"/waste_bin/upload_waste_bin_excel/";
export const DELETE_BIN_ACCOUNT_URL = API_URL+"/waste_bin/";
export const UPDATE_BIN_LIST_URL = API_URL+"/waste_bin/";
export const GET_DISPOSE_LIST_URL = API_URL+"/dispose/my_dispose_list/";
export const GET_PROCESS_LIST_URL = API_URL+"/wearing/my_process_list/";
export const GET_DRIVER_DISPOSE_URL = API_URL+"/dispose/my_driver_dispose_list/";
export const COMPANY_DATA_URL = API_URL+"/company/data_list/";
export const POST_CHILDREN_CONTRACT_URL = API_URL+"/contract_company_group/children_contract_list/";
export const POST_TARGET_COMPANY_URL = API_URL+"/contract_company_group/get_target_company/";
export const POST_PREV_COLLECT_URL = API_URL+"/waste_collect/children_company_collect_report_prev/";
export const POST_COLLECT_REPORT_SUM_URL = API_URL+"/waste_collect/children_company_collect_report/";
export const POST_SIGN_FILE_URL = API_URL+"/member_file/sign_file_upload/";
export const PATCH_COMPANY_DETAIL_URL = API_URL+"/company_detail/";
export const MEMBER_FILE_URL = API_URL+"/member_file/";
export const POST_PAYMENT_LOG_URL = API_URL+"/payment_report/insert_payment_log/";
export const POST_PAYMENT_REPORT_URL = API_URL+"/payment_report/insert_payment/";
export const POST_TAX_URL = API_URL+"/taxbill/regist_issue/";
export const POST_TAX_PDF_URL = API_URL+"/taxbill/get_pdf_url/";
export const POST_WASTE_COLLECTION_URL = API_URL+"/waste_collect/insert_collect/";
export const GET_ALLBARO_DATA_URL = API_URL+"/dispose/get_allbaro_dispose_list/";
export const GET_CAR_URL = API_URL+"/car/data_list/";
export const CHECK_CAR_NUMBER_URL = API_URL+"/car/check_number/";
export const SAVE_CAR_NUMBER_URL = API_URL+"/car/";
export const SAVE_CAR_EXCEL_URL = API_URL+"/car/upload_car_number_excel/";
export const DELETE_CAR_NUMBER_URL = API_URL+"/car/";
export const GET_BUSINESS_COMPANY_URL = API_URL+"/company/company_total_list/";
export const UPLOAD_COMPANY_LIST_EXCEL_URL = API_URL+"/company/children_company_file_upload/";
export const CODE_URL = API_URL+"/code/";
export const TERMS_URL = API_URL+"/terms/";
export const GET_TARGET_COMPANY_URL = API_URL+"/allbaro/get_target_company_list/";
export const SEND_ALLBARO_EMAIL_URL = API_URL+"/allbaro/send_allbaro_email/";
export const REGIST_TAX_BILL_URL = API_URL+"/taxbill/regist_issue/";
export const GET_ADVERTISEMENT_URL = API_URL+"/advertisement/";
export const TRADE_REPLAY_URL = API_URL+"/trade_replay/";
export const WITHDRAWL_URL = API_URL+"/member/withdraw_user/";
export const SOLAR_VOLTAGE_UPDATE_URL = API_URL+"/pv_volt_update";

export const REFRESH_TOKEN_URL = API_URL+"/token/refresh/";
// export const 
// export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";

// //export const ME_URL = "api/me";

// export const LOGIN_URL = "/rest/auth/signin";
// export const THE_APT_LOGIN_URL = "/rest/auth/theAptSignin";
// export const ME_URL = "/rest/me/manager";

// export const REFRESH_URL = "/rest/auth/manager/refreshToken";

// export const PHONE_SEND_NUMBER = "/rest/auth/send/authNumber"
// export const PHONE_SEND_NUMBER_CHECK = "/rest/auth/send/authNumberCheck"

// export function login(userId, passwd, userType) {
//   return axiosApi.post(LOGIN_URL, { userId, passwd, userType});
// }

export function login(params) {  
  return axios.post(LOGIN_URL, params)
}

export function userData(params) {
  return axios.post(GET_USER_DATA_URL, params)
}

export function register(data) {  
  return axios.post(REGISTER_URL, data, {headers : {'Content-Type' : 'multipart/form-data'} });
}

export function checkId(param) {
  // console.log(axiosApi.post(CHECK_ID_URL, param))
  return axios.post(CHECK_ID_URL, param, {headers: {}});
}

// 발주처 정보 가져오기

//디바이스 정보 가져오기
export function getIotDeviceList() {
  return axios.get(GET_IOT_DEVICE_URL
    // + '?page=' + params.page + '&order=' + params.order +'&type=' + params.type + '&search='+params.search
  )
}
//디바이스 드롭다운용
export function getDropdownDevice() {
  return axios.get(GET_IOT_DEVICE_URL)
}
//기기1개 조회
export function postIotDevice(params) {
  return axios.post(GET_IOT_DEVICE, params)
}
//검색기기 리스트 가져오기
export function getDeviceSearchList(params) {
  return axios.post(POST_DEVICE_SEARCH_LIST_URL, params)
}
//기기 범위 검색
export function postDeviceRangeSearch(params) {
  return axios.post(DEVICE_RANGE_SEARCH_URL, params)
}
//기기 등록
export function postAddDevice(params) {
  return axios.post(POST_ADD_DEVICE_URL, params )
}
//기기ID 교체
export function postUpdateDeviceId(params) {
  return axios.post(UPDATE_DEVICE_ID_URL, params)
}
//기기1개 조회
export function postUpdateIotDevice(params) {
  return axios.post(POST_UPDATE_IOT_DEVICE, params)
}
//기기 정보 전송
export function postCallSocketTypeA(params) {
  return axios.post(POST_CALL_SOCKET_TYPE_A_URL, params )
}
//사전추가된 미등록 기기 조회
export function getDeviceUnsignSearchList(params) {
  return axios.post(POST_DEVICE_UNSIGN_SEARCH_LIST_URL, params)
}
//사전추가된 미등록 기기 등록
export function postDeviceUnsignPairing(params) {
  return axios.post(POST_DEVICE_PAIRING_URL, params)
}
//사전추가된 미등록 기기 해제
export function postDeviceUnsignUnPairing(params) {
  return axios.post(POST_DEVICE_UNPAIRING_URL, params)
}

//기기로 전압 요청
export function postPvVoltUpdate(params) {
  return axios.post(POST_PV_VOLT_UPDATE, params)
}
//기기로 위치 요청
export function postPositionUpdate(params) {
  return axios.post(POST_POSITION_UPDATE, params)
}
//기기로 전압 요청
export function postAsStatusUpdate(params) {
  return axios.post(POST_AS_STATUS_UPDATE, params)
}


//미등록 기기 조회
export function getUnsignDeviceSearchList(params) {
  return axios.post(POST_UNSIGN_DEVICE_SEARCH_LIST_URL, params)
}
//미등록 기기 등록
export function postUnsignDevicePairing(params) {
  return axios.post(POST_UNSIGN_DEVICE_PAIRING_URL, params)
}
//미등록 기기 해제
export function postUnsignDeviceUnPairing(params) {
  return axios.post(POST_UNSIGN_DEVICE_UNPAIRING_URL, params)
}




export function getIotDeviceErrorList(params) {
  return axios.get(GET_IOT_DEVICE_ERROR_HISTORY_URL, params)
}


export function postIotDeviceHistoryList(params) {
  return axios.post(GET_IOT_DEVICE_HISTORY_URL, params)
}

export function getDimDeviceList(params) {
  return axios.post(GET_DIMMING_LIST, params)
}

//솔라 기준전압 업데이트
export function postSolarVoltageUpdate(params) {
  return axios.post(SOLAR_VOLTAGE_UPDATE_URL, params)
}
export function getAllIotDeviceHistoryList() {
  return axios.get(GET_ALL_IOT_DEVICE_HISTORY_URL)
}
export function getIotDeviceSolarList() {
  return axios.get(GET_IOT_DEVICE_SOLAR_URL)
}
export function getIotDeviceBatteryList() {
  return axios.get(GET_IOT_DEVICE_BATTERY_URL)
}

//발주처 리스트 가져오기
export function getCompanyList(params) {
  return axios.get(GET_COMPANY_LIST_URL
    + '?page=' + params.page + '&order=' + params.order +'&type=' + params.type + '&search='+params.search
    )
}
//발주처 상세 가져오기
export function getCompanyDetail(params) {
  return axios.get(GET_COMPANY_URL + params.id)
}
//검색발주처 리스트 가져오기
export function getCompanySearchList(params) {
  return axios.post(POST_COMPANY_SEARCH_LIST_URL, params)
}
//발주처 등록
export function postAddCompany(params) {
  return axios.post(POST_ADD_COMPANY_URL, params )
}



//이벤트 리스트 가져오기
export function getHistoryList(params) {
  return axios.get(GET_HISTORY_LIST_URL
    + '?page=' + params.page + '&order=' + params.order +'&type=' + params.type + '&search='+params.search
    )
}
//검색이벤트 리스트 가져오기
export function getHistorySearchList(params) {
  return axios.post(POST_HISTORY_SEARCH_LIST_URL, params)
}


//유저리스트 가져오기
export function getUserList(params) {
  return axios.get(GET_USER_LIST_URL
     + '?page=' + params.page + '&order=' + params.order +'&type=' + params.type + '&search='+params.search
     )
}

//유저리스트 가져오기
export function getUserSearchList(params) {
  return axios.post(POST_USER_SEARCH_LIST_URL, params)
}

//유저 정보 가져오기
export function getUserDetail(params) {
  return axios.get(GET_USER_DETAIL_URL+ '/' + params.id   )
}

//유저 등록
export function postAddUser(params) {
  return axios.post(POST_ADD_USER_URL, params )
}

export function postUpdateUser(params) {
  return axios.post(POST_UPDATE_USER_URL, params )
}






export function getTerms() {
  return axios.get(TERMS_URL)
}

export function getCode() {
  return axios.get(CODE_URL)
}

export function mainResponse(param) {
  return axios.post(MAIN_RESPONSE_URL, param);
}

export function dashboardResponse() {
  return axios.get(DASHBOARD_URL);
}

export function newsResponse() {
  return axios.get(NEWS_URL);
}

export function companyListCall(params) {
  return axios.get(COMPANY_DATA_URL + '?page=' + params.page + '&type=' + params.type + '&search='+params.search)
}

export function emitterListCall(params) {
  return axios.get(COMPANY_DATA_URL + '?page=' + params.page + '&type=' + params.type + '&search='+params.search)
}

export function contractCall(params) {
  return axios.get(CONTRACT_URL + '?page=' + params.page + '&state=Y&company=' + params.company)
}

export function uncontractCall(params) {
  return axios.get(CONTRACT_URL + '?page=' + params.page + '&state=N&company=' + params.company)
}

export function contractWriteResponse(params) {
  return axios.post(CONTRACT_WRITE_URL, params, {headers : {'Content-Type' : 'multipart/form-data'} });
}

export function contractWriteHistory(params) {
  return axios.post(CONTRACT_WRITE_HISTORY_URL, params);
}

export function tradeHistoryCall(param) {
  return axios.get(TRADE_HISTORY_URL + '/?page=' + param.page + '&company=' + param.company + '&start_ym=' + param.start_ym + '&end_ym=' + param.end_ym);
}

export function companyResponse(param, urlplus) {
  return axios.patch(COMPANY_URL + urlplus + "/", param);
}

export function memberResponse(param, urlplus) {
  return axios.patch(MEMBER_RESPONSE_URL + urlplus + "/", param);
}

export function tmsCompanyResponse(company) {
  return axios.get(TMS_COMPANY_RESPONSE_URL + '?id=' + company + '&company_type=collection');
}
export function tmsResponse(param) {
  return axios.post(TMS_RESPONSE_URL, param);
}

export function chartResponse(param) {
  return axios.post(MAIN_RESPONSE_URL, param);
}

export function chartTotalResponse(param) {
  return axios.post(CHART_TOTAL_URL, param);
}

export function faqResponse() {
  return axios.get(FAQ_URL + '?page=1');
}

export function contractDetailResponse(params) {
  return axios.get(CONTRACT_DETAIL_URL + params);
}

export function patchContract(fd) {
  return axios.post(CONTRACT_PATCH_URL, fd, 
  {headers : {'Content-Type' : 'multipart/form-data'} }
  );
}

export function otherCompanyResponse(params) {
  return axios.get(OTHER_COMPANY_URL + params);
}

export function contractDelete(params) {
  return axios.patch(CONTRACT_DELETE_URL + params + "/", {status : 'N'});
}

export function passwordChange(params) {
  return axios.post(PASSWORD_CHANGE_URL, params);
}

export function signChange(params) {
  return axios.post(SIGN_CHANGE_URL, params, {headers : {'Content-Type' : 'multipart/form-data'}});
}

export function settingChange(param, urlplus) {
  return axios.patch(MEMBER_RESPONSE_URL + urlplus + "/", param)
}

export function settingConfirm(param) {
  return axios.get(MEMBER_RESPONSE_URL + param + "/")
}

export function alarmResponse(param) {
  return axios.get(ALARM_RESPONSE_URL + param.page + "&member_id=" + param.memberId)
}

export function alarmView(param) {
  return axios.post(ALARM_VIEW_URL, param)
}

export function alarmDelete(param, urlplus) {
  return axios.patch(ALARM_DELETE_URL + urlplus + "/", param)
}

export function driverResponse(param) {
  return axios.post(DRIVER_RESPONSE_URL, param)
}

export function driverCompanyResponse(param) {
  return axios.get(DRIVER_COMPANY_URL + param)
}

export function disposeDataResponse(param) {
  return axios.post(DISPOSE_DATA_RESPONSE_URL, param)
}

export function listDelete(param) {
  return axios.delete(LIST_DELETE_URL + param + "/")
}

export function disposeWearing(param) {
  return axios.post(DISPOSE_WEARING_URL, param)
}

export function uncollectUpload(param) {
  return axios.post(UNCOLLECT_UPLOAD_URL, param)
}

// 사업장 리스트
export function getBusinessList(param) {
  return axios.get(BUSINESS_LIST_URL + param)
}
export function postCollectData(param) {
  return axios.post(POST_COLLECT_DATA_URL, param)
}
export function postMyContract(param) {
  return axios.post(POST_MY_CONTRACT_URL, param)
}

export function patchBusinessList(id, param) {
  return axios.patch(COMPANY_URL + id + "/", param)
}

export function setNewBusinessList(param) {
  return axios.post(NEW_BUSINESS_URL, param, {headers: {'Content-Type': 'multipart/form-data'},})
}

export function doSign(param) {
  return axios.post(SIGN_URL, {id : param.id, contract : param.contract, sign : param.sign, company : param.company})
}

export function getProductList() {
  return axios.get(PRODUCT_LIST_URL)
}

export function getSellProductList(param) {
  return axios.get(PRODUCT_SELL_LIST_URL + param.page + '&type=sell&search=' + param.search)
}

export function getBuyProductList(param) {
  return axios.get(PRODUCT_SELL_LIST_URL + param.page + '&type=buy&search=' + param.search)
}

export function getSellProductDetail(param) {
  return axios.get(PRODUCT_SELL_DETAIL_URL + param + '/')
}

export function postTradeRequest(param) {
  return axios.post(PRODUCT_REQUEST_URL, param)
}

// 판매완료 함수들
export function sellComplete1(param, date) {
  return axios.patch(SELL_COMPLETE_ONE_URL + param + '/', {state : 'Y', complete_date : date})
}
export function sellComplete2(param, date) {
  return axios.patch(SELL_COMPLETE_TWO_URL + param + '/', {state : 'Y', complete_date : date})
}
export function sellComplete3(param) {
  return axios.post(SELL_COMPLETE_THREE_URL, param)
}
export function sellComplete4(param) {
  return axios.post(SELL_COMPLETE_FOUR_URL, param)
}

// 판매취소 함수들
export function sellDelete1(param) {
  return axios.patch(SELL_DELETE_ONE_URL + param + '/', {status : 'N'})
}
export function sellDelete2(param) {
  return axios.patch(SELL_DELETE_TWO_URL + param + '/', {state : 'N'})
}
export function sellDelete3(param) {
  return axios.post(SELL_DELETE_THREE_URL, param)
}

export function postBuyWrite(param) {
  return axios.post(BUY_WRITE_URL, param, {headers: {'Content-Type': 'multipart/form-data'}})
}

//메인 홈 데이터
export function postWearing(param) {
  return axios.post(POST_WEARING_URL, param)
}
export function postProcessingWearing(param) {
  return axios.post(POST_PROCESSING_WEARING_URL, param)
}
export function postCollectSum(param) {
  return axios.post(POST_COLLECT_SUM_URL, param)
}
export function postDisposeSum(param) {
  return axios.post(POST_DISPOSE_SUM_URL, param)
}

export function getWearingList(param) {
  return axios.get(GET_WEARING_LIST_URL
    + '?page=' + param.page + '&company=' + param.company + '&start_date=' + param.start_date + '&end_date=' + param.end_date
    )
}

export function getCollectList(param) {
  return axios.get(GET_COLLECT_LIST_URL
    + '?page=' + param.page + '&driver_company=' + param.driver_company + '&start_date=' + param.start_date + '&end_date=' + param.end_date
    )
}

export function getMyProduct(param) {
  return axios.get(MY_PRODUCT_LIST_URL + '?page=' + param.page + '&member=' + param.member + '&search=' + param.search)
}

export function postSendingCode(param) {
  return axios.post(SENDING_CODE_URL, param)
}

export function postCheckCode(param) {
  return axios.post(CHECK_CODE_URL, param)
}

export function postSetPassword(param) {
  return axios.post(NEW_PASSWORD_URL, param)
}

// 운반기사 관리
export function getDriverList(param) {
  return axios.get(GET_DRIVER_LIST_URL + '?page=' + param.page + '&company=' + param.company)
}
export function checkDriverId(param) {
  return axios.post(CHECK_DRIVER_ID_URL, param, {headers: {}})
}
export function saveDriverAccount(param) {
  return axios.post(SAVE_DRIVER_ACCOUNT_URL, param)
}
export function saveDriverExcel(param) {
  return axios.post(SAVE_DRIVER_EXCEL_URL, param, { headers: { 'Content-Type': 'multipart/form-data' },})
}
export function deleteDriverAccount(param, id) {
  return axios.patch(DELETE_DRIVER_ACCOUNT_URL + id + '/', param)
}
export function updateDriverList(param, id) {
  return axios.patch(UPDATE_DRIVER_LIST_URL + id + '/', param)
}

//  IoT Monitor바 관리
export function getWaverBarList(param) {
  return axios.get(GET_WAVER_BAR_URL + '?page=' + param.page + '&company=' + param.company)
}
export function checkWaverBarNumber(param) {
  return axios.post(CHECK_WAVER_BAR_NUMBER_URL, param, {headers: {}})
}
export function saveWaverBarNumber(param) {
  return axios.post(SAVE_WAVER_BAR_NUMBER_URL, param)
}
export function saveWaverBarExcel(param) {
  return axios.post(SAVE_WAVER_BAR_EXCEL_URL, param, { headers: { 'Content-Type': 'multipart/form-data' },})
}
export function deleteWaverBarNumber(param, id) {
  return axios.patch(DELETE_WAVER_BAR_NUMBER_URL + id + '/', param)
}

// 쓰레기통 관리
export function getBinList(param) {
  return axios.get(GET_BIN_LIST_URL + '?page=' + param.page + '&company=' + param.company)
}
export function getBinCompany(id) {
  return axios.get(GET_BIN_COMPANY_URL + '?id=' + id + '&company_type=emitter')
}
export function checkBinId(param) {
  return axios.post(CHECK_BIN_ID_URL, param, {headers: {}})
}
export function saveBinAccount(param) {
  return axios.post(SAVE_BIN_ACCOUNT_URL, param)
}
export function saveBinExcel(param) {
  return axios.post(SAVE_BIN_EXCEL_URL, param, { headers: { 'Content-Type': 'multipart/form-data' },})
}
export function deleteBinAccount(param, id) {
  return axios.patch(DELETE_BIN_ACCOUNT_URL + id + '/', param)
}
export function updateBinList(param, id) {
  return axios.patch(UPDATE_BIN_LIST_URL + id + '/', param)
}

// 차량번호 관리
export function getCarList(param) {
  return axios.get(GET_CAR_URL + '?page=' + param.page + '&company=' + param.company)
}
export function checkCarNumber(param) {
  return axios.post(CHECK_CAR_NUMBER_URL, param, {headers: {}})
}
export function saveCarNumber(param) {
  return axios.post(SAVE_CAR_NUMBER_URL, param)
}
export function saveCarExcel(param) {
  return axios.post(SAVE_CAR_EXCEL_URL, param, { headers: { 'Content-Type': 'multipart/form-data' },})
}
export function deleteCarNumber(param, id) {
  return axios.patch(DELETE_CAR_NUMBER_URL + id + '/', param)
}

export function getDisposeList(param) {
  return axios.get(GET_DISPOSE_LIST_URL + '?page=' + param.page + '&company=' + param.company + '&start_date=' + param.start_date+ '&end_date=' + param.end_date)
}

export function getProcessList(param) {
  return axios.get(GET_PROCESS_LIST_URL + '?page=' + param.page + '&company=' + param.company + '&start_date=' + param.start_date+ '&end_date=' + param.end_date)
}

export function getDriverDispose(param) {
  return axios.get(GET_DRIVER_DISPOSE_URL + '?page=' + param.page + '&company=' + param.company + '&member=' + param.member + '&start_date=' + param.start_date+ '&end_date=' + param.end_date)
}

export function postChildrenContract(param) {
  return axios.post(POST_CHILDREN_CONTRACT_URL, param)
}

export function postTargetCompany(param) {
  return axios.post(POST_TARGET_COMPANY_URL, param)
}

export function postPrevCollect(param) {
  return axios.post(POST_PREV_COLLECT_URL, param)
}

export function postCollectReportSum(param) {
  return axios.post(POST_COLLECT_REPORT_SUM_URL, param)
}

export function postSignFile(param) {
  return axios.post(POST_SIGN_FILE_URL, param, { headers: { 'Content-Type': 'multipart/form-data' }, })
}

export function patchCompanyData(id, param) {
  return axios.patch(COMPANY_URL + id + '/', param)
}

export function patchProfileData(id, param) {
  return axios.patch(MEMBER_RESPONSE_URL + id + '/', param)
}

export function postMemberFile(param) {
  return axios.post(SIGN_CHANGE_URL, param, { headers: { 'Content-Type': 'multipart/form-data' }, })
}

export function patchCompanyDetail(id, param) {
  return axios.patch(PATCH_COMPANY_DETAIL_URL + id + '/', param)
}

export function deleteMemberFile(param) {
  return axios.delete(MEMBER_FILE_URL + param + '/')
}

export function getMyProfile(id) {
  return axios.get(MEMBER_RESPONSE_URL + id + '/')
}

export function getPaymentList(url, params) {
  return axios.get(API_URL + url + params.company + '&date=' + params.date)
}

export function postPaymentLog(params) {
  return axios.post(POST_PAYMENT_LOG_URL, params)
}

export function postPaymentReport(params) {
  return axios.post(POST_PAYMENT_REPORT_URL, params)
}

export function getSettlement(url) {
  return axios.get(API_URL + url)
}

export function postTax(params) {
  return axios.post(POST_TAX_URL, params)
}

export function getCompanyOrder(data) {
  return axios.get(COMPANY_URL + data + '/')
}

export function postTaxPdf(params) {
  return axios.post(POST_TAX_PDF_URL, params)
}

export function postWasteData(fd) {
  return axios.post(POST_WASTE_COLLECTION_URL, fd, {headers: {'Content-Type': 'multipart/form-data'}})
}

export function postWasteCollection(params) {
  return axios.post(POST_WASTE_COLLECTION_URL, params)
}

export function getAllbaroData(params) {
  return axios.get(GET_ALLBARO_DATA_URL + '?page=' + params.page + '&company=' + params.company)
}

// 종합배출자 사업장 목록
export function getBusinessCompany(param) {
  return axios.get(GET_BUSINESS_COMPANY_URL + '?id=' + param)
}
export function deleteChildrenCompanyList(id, param) {
  return axios.patch(COMPANY_URL + id + '/', param)
}
export function uploadCompanyListExcel(fd) {
  return axios.post(UPLOAD_COMPANY_LIST_EXCEL_URL, fd, { headers: { 'Content-Type': 'multipart/form-data' }})
}

export function getTargetCompany(param) {
  return axios.get(GET_TARGET_COMPANY_URL + '?company=' + param)
}

export function sendAllbaroEmail(param) {
  return axios.post(SEND_ALLBARO_EMAIL_URL, param)
}

export function fromRsData(id) {
  return axios.get(COMPANY_URL + id + '/')
}
export function toRsData(id) {
  return axios.get(COMPANY_URL + id + '/')
}
export function registTaxBill(param) {
  return axios.post(REGIST_TAX_BILL_URL, param)
}
export function getAdvertisement() {
  console.log(GET_ADVERTISEMENT_URL + '?page=1')
  return axios.get(GET_ADVERTISEMENT_URL + '?page=1')
}

export function postReplay(param) {
  return axios.post(TRADE_REPLAY_URL, param)
}
export function patchReplay(id) {
  return axios.patch(TRADE_REPLAY_URL + id + '/', {status: 'N'})
}
export function sendReplay(param) {
  return axios.post(TRADE_REPLAY_URL, param)
}

export function withdrawlUser(param) {
  return axios.post(WITHDRAWL_URL, param)
}
export function deleteAuth() {
  return delete axios.defaults.headers.common['Authorization']
}

export function patchTradeRequest1(id) {
  return axios.patch(SELL_COMPLETE_ONE_URL + id + '/', {status : 'N'})
}
export function patchTradeRequest2(id) {
  return axios.patch(SELL_COMPLETE_TWO_URL + id + '/', {state : 'N'})
}
export function patchTradeRequest3(param) {
  return axios.post(SELL_COMPLETE_FOUR_URL, param)
}

export function patchtradeReply(id) {
  return axios.patch(TRADE_REPLAY_URL + id + '/', {status: 'N'})
}


// export function getMyProduct(param) {
//   return axios.get(MY_PRODUCT_LIST_URL + '?page=' + param.page + '&member=' + param.member + '&search=' + param.search)
// }

// export function file(param, urlplus) {
//   return axios.patch(ALARM_DELETE_URL + urlplus + "/", param)
// }
// SIGN_CHANGE_URL
// export function theAptLogin(ssotoken, ssoid) {
//   return axios.get(THE_APT_LOGIN_URL + '?ssotoken=' + ssotoken + '&ssoid='+ssoid)
// }

// const instance = axios.create();
// instance.interceptors.request.use(
//   config => {
//     const {
//       auth: { refreshToken }
//     } = store.getState();

//     if (refreshToken) {
//       config.headers.Authorization = `Bearer ${refreshToken}`;
//     }

//     return config;
//   },
//   err => Promise.reject(err)
// );

// export function refresh(refreshToken) {
//   return instance.get(REFRESH_URL, {});
// }

// export function mobileApprovalRefresh(token) {
//   let config = {
//     headers : {
//       Authorization : `Bearer `
//     }
//   };
//   if (token) {
//     config.headers.Authorization = `Bearer ${token}`;
//   }
//   return instance.get(REFRESH_URL, config);
// }

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.post(MY_PROFILE_URL, {});
}

// export function sendNumber(data) {
//   return axios.post(PHONE_SEND_NUMBER, data );
// }

// export function sendNumberCheck(data) {
//   return axios.post(PHONE_SEND_NUMBER_CHECK, data );
// }